.share-class-block > div {
  max-width: var(--wp--style--global--wide-size);
  width: 80%;
  margin: 0 auto;
  padding: var(--wp--preset--spacing--60) 0;
}
@media only screen and (max-width: 1024px) {
  .share-class-block > div {
    padding: var(--wp--preset--spacing--50) 0;
  }
}
.share-class-block > div.narrow {
  max-width: var(--wp--style--global--content-size) !important;
}
.share-class-block > div.full-width {
  max-width: none !important;
  width: 100% !important;
}
.share-class-block .content-col {
  text-align: center;
  max-width: 400px;
  margin: 0 auto;
}
.share-class-block.dark {
  background: none;
  background-color: var(--wp--preset--color--dark-green);
}
.share-class-block.green {
  background: none;
  background-color: var(--wp--preset--color--bnpp-green);
}
.share-class-block.charcoal {
  background: none;
  background-color: var(--wp--preset--color--charcoal);
}
.share-class-block:is(.dark, .green, .charcoal) * {
  color: var(--wp--preset--color--white);
}
.share-class-block:is(.dark, .green, .charcoal).contained {
  background-color: var(--wp--preset--color--dark-green);
  padding: var(--wp--preset--spacing--60);
}
.share-class-block:is(.dark, .green, .charcoal).contained *:not(path):not(.bnpp-button):not(.button-icon) {
  color: var(--wp--preset--color--bnpp-black);
}
.share-class-block:is(.dark, .green, .charcoal).contained > div {
  background-color: var(--wp--preset--color--white);
}
.share-class-block:is(.dark, .green, .charcoal).contained h2, .share-class-block:is(.dark, .green, .charcoal).contained p {
  color: var(--wp--preset--color--bnpp-black);
}
.share-class-block.contained {
  background-color: var(--wp--preset--color--white);
  padding: var(--wp--preset--spacing--60);
}
.share-class-block.contained * {
  color: var(--wp--preset--color--white);
}
.share-class-block.contained > div {
  background-color: var(--wp--preset--color--dark-green);
}
.share-class-block.full-width {
  max-width: none;
  width: 100%;
  padding: 0;
}
.share-class-block.dark svg :is(*[style*="1d1d1b"], *[style*="rgb(29, 29, 27)"]) {
  stroke: var(--wp--preset--color--white) !important;
}